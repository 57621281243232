import React, { ReactNode, useEffect, useState } from 'react';

import { useWindowSize } from 'utils/hooks/useWindowSize';

import { Button } from 'components/Shared/Inputs/Button';

import { Header } from './Header';
import { HeaderCellProps } from './Header/Header';
import { Information } from './Information';
import { ITableAction } from './Information/Information';
import { Row } from './Row';
import { RowSkeleton } from './RowSkeleton';

type Data = {
  columns: ReactNode[];
  data: Record<string, any> & {
    id: string;
  };
  sortableValues?: Record<number, number | string | Date>;
}[];

type SortDirectionType = -1 | 0 | 1;

export interface TableProps {
  actions?: ITableAction[];
  children?: ReactNode | ((props: { isSelectedList: any; selectedObjects: Record<string, any>[] }) => ReactNode);
  className?: string;
  columns: HeaderCellProps[];
  data: Data;
  defaultValue?: number;
  disableSelect?: boolean;
  expandableClassName?: string;
  extendDirection?: 'before' | 'after' | 'both';
  hasHeader?: boolean;
  informationMessage?: string;
  isDisplayButtonTooltip?: boolean;
  isDisplayUploadedTasks?: boolean;
  isInformationVisible?: boolean;
  isSub?: boolean;
  isLoading?: boolean;
  isTableSelectionVisible?: boolean;
  itemName: string;
  loadMore?: () => void;
  loading?: boolean;
  onPageSizeChange?: (evt) => void;
  preSelectIds?: number[];
  selectable?: boolean;
  selectedFlowId?: number;
  selectedState?: [number[], (updater: ((currentState: number[]) => number[]) | number[]) => void];
  selectionOptions?: any;
  setSelectedTable?: any;
  tableName?: string;
  totalCount?: number;
  withMultipleSelect?: boolean;
}

function defaultDataState(dataList: Data, value = false, trueIds = []) {
  const obj = {};
  dataList.forEach(({ data: rowData }) => {
    obj[rowData.id] = trueIds.includes(rowData.id) || value;
  });
  return obj;
}

function Table({
  actions,
  className,
  columns,
  children,
  data,
  defaultValue,
  disableSelect,
  expandableClassName,
  extendDirection = 'both',
  itemName,
  loadMore,
  isDisplayUploadedTasks,
  isSub,
  isLoading,
  hasHeader = true,
  preSelectIds,
  selectable,
  selectedState,
  selectedFlowId,
  onPageSizeChange,
  totalCount,
  tableName,
  informationMessage,
  isInformationVisible = false,
  isTableSelectionVisible = false,
  selectionOptions,
  setSelectedTable,
  withMultipleSelect = true,
}: TableProps) {
  const hasMore = totalCount > data.length;
  const { isMobile, isTablet } = useWindowSize();

  const hasExpandable =
    columns.find((column) => column.priority === 3) ||
    (columns.find((column) => column.priority === 2) && (isTablet || isMobile)) ||
    (columns.find((column) => column.priority === 1) && isMobile);

  const localSelectedState = useState(() => defaultDataState(data));

  const [isSelectedList, setIsSelectedList] = selectedState || localSelectedState;

  const selectedIds = Object.entries(isSelectedList)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const selectedObjects = data
    .map((row) => row.data)
    .filter((currentRow) => {
      return selectedIds.includes(String(currentRow.id));
    });

  const allSelected = Object.values(isSelectedList).length > 0 && Object.values(isSelectedList).every((val) => val);

  function toggleSelectAll() {
    setIsSelectedList(defaultDataState(data, !allSelected));
  }

  function toggleRowSelection(rowData) {
    setIsSelectedList((currentState) => {
      return { ...currentState, [rowData.id]: !currentState[rowData.id] };
    });
  }

  const [isExpandedList, setIsExpandedList] = useState(() => {
    return defaultDataState(data);
  });

  const allExpanded = Object.values(isExpandedList).length > 0 && Object.values(isExpandedList).every((val) => val);

  function toggleExpandAll() {
    setIsExpandedList(defaultDataState(data, !allExpanded));
  }

  function toggleRowExpanded(rowNumber) {
    setIsExpandedList((currentState) => {
      return { ...currentState, [rowNumber]: !currentState[rowNumber] };
    });
  }

  useEffect(() => {
    if (!data) {
      return;
    }

    setIsSelectedList(defaultDataState(data, false, preSelectIds));
  }, [preSelectIds]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (Object.keys(isExpandedList).length === 0) {
      setIsExpandedList(defaultDataState(data));
    }

    if (Object.keys(isSelectedList).length === 0) {
      setIsSelectedList(defaultDataState(data));
    }
  }, [data.length]);

  const [columnSorted, setColumnSorted] = useState<number>();
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(0);

  function handleSortClicked(columnIndex: number) {
    if (columnSorted === columnIndex) {
      setSortDirection((currentState: SortDirectionType) => {
        return {
          '-1': 1,
          '1': -1,
        }[String(currentState)] as SortDirectionType;
      });
    } else {
      setColumnSorted(columnIndex);
      setSortDirection(1);
    }
  }

  const sortItem = data?.[0]?.sortableValues?.[columnSorted];
  const sortType = typeof sortItem;

  if (columnSorted != null) {
    data.sort((a, b) => {
      if (sortType === 'string') {
        if (String(sortItem).includes('/')) {
          const aVal = new Date(a.sortableValues[columnSorted]) as Date;
          const bVal = new Date(b.sortableValues[columnSorted]) as Date;
          return (aVal.getTime() - bVal.getTime()) * sortDirection;
        }

        const aVal = a.sortableValues[columnSorted] as string;
        const bVal = b.sortableValues[columnSorted] as string;
        return aVal.localeCompare(bVal) * sortDirection;
      }

      if (sortType === 'number') {
        const aVal = a.sortableValues[columnSorted] as number;
        const bVal = b.sortableValues[columnSorted] as number;
        return (aVal - bVal) * sortDirection;
      }

      return 0;
    });
  }

  return (
    <>
      {(totalCount >= 0 || actions || selectable || onPageSizeChange) && (
        <Information
          actions={actions}
          extendDirection={extendDirection}
          isSelectedList={isSelectedList}
          itemName={itemName}
          selectedObjects={selectedObjects}
          totalCount={totalCount}
          informationMessage={informationMessage}
          isInformationVisible={isInformationVisible}
          isTableSelectionVisible={isTableSelectionVisible}
          selectionOptions={selectionOptions}
          setSelectedTable={setSelectedTable}
          onPageSizeChange={onPageSizeChange}
          defaultValue={defaultValue}
        />
      )}
      <table className={`c-table l-full-width ${className}`}>
        {hasHeader && (
          <thead>
            <Header
              columns={columns}
              disableSelect={disableSelect}
              expandable={
                hasExpandable
                  ? {
                      allExpanded,
                      onAllExpandClicked: toggleExpandAll,
                    }
                  : undefined
              }
              extendDirection={extendDirection}
              isSub={isSub}
              onSortClicked={handleSortClicked}
              selectable={
                selectable
                  ? {
                      allSelected,
                      onAllSelectClicked: toggleSelectAll,
                    }
                  : undefined
              }
              sorted={{ columnIndex: columnSorted, direction: sortDirection }}
              withMultipleSelect={withMultipleSelect}
              tableName={tableName}
            />
          </thead>
        )}
        <tbody>
          {data.map(({ data: rowData, columns: rowColumns }, rowNumber) => {
            const dataColumns = rowColumns.map((content, index) => {
              return {
                ...columns[index],
                content,
              };
            });

            const { isSelectable = true } = rowData;

            return (
              <Row
                className={isExpandedList[rowData.id] ? 'is-expanded' : ''}
                columns={dataColumns}
                disableSelect={disableSelect || !isSelectable}
                expandable={
                  hasExpandable
                    ? {
                        isExpanded: isExpandedList[rowData.id] || false,
                        onExpandClicked: () => toggleRowExpanded(rowData.id),
                      }
                    : undefined
                }
                expandableClassName={expandableClassName}
                extendDirection={extendDirection}
                key={rowData.id}
                selectedFlowId={selectedFlowId}
                studentId={rowData.user && rowData.user.id}
                isDisplayUploadedTasks={isDisplayUploadedTasks}
                isSub={isSub}
                rowIndex={rowNumber}
                selectable={
                  selectable
                    ? {
                        isSelected: isSelectedList[rowData.id] || false,
                        onSelectClicked: () => toggleRowSelection(rowData),
                      }
                    : undefined
                }
              />
            );
          })}
          {isLoading && <RowSkeleton />}
        </tbody>
      </table>
      {hasMore && loadMore && (
        <div className="l-flex l-flex--center h-with-margin">
          <Button icon="arrow">View more</Button>
        </div>
      )}
      {typeof children === 'function' ? children({ isSelectedList, selectedObjects }) : children}
    </>
  );
}

export { Table };
