import cx from 'classnames';

import { InformationProps } from './Information.interfaces';

const Information: React.FC<InformationProps> = ({ additionalClassNames, ariaLabel = 'Information: ', message, typeLetter = 'i' }) => {
  const informationClassNames = cx('l-flex l-flex--align-center l-flex__item--column h-with-x-padding', additionalClassNames);

  return (
    <div className={informationClassNames}>
      <span
        className="l-flex__item--row h-circled h-background--color-brand l-flex l-flex--center c-typography--bold"
        aria-label={ariaLabel}
      >
        {typeLetter}
      </span>
      <span className="h-with-x-padding c-typography--bold">{message}</span>
    </div>
  );
};

export { Information };
