import React from 'react';

import cx from 'classnames';
import pluralize from 'pluralize';

import { Button, ButtonLink } from 'components/Shared/Inputs/Button';
import { IconTypes } from 'components/Shared/UI/Icons/Icons';
import { Information as InformationMessage } from 'components/Shared/UI/Information';
import { Select } from 'components/Shared/Inputs/Select';

export interface ITableAction {
  buildHref?: (arg: { isSelectedList: { [id: number]: boolean }; selectedIds: string[]; selectedObjects: Record<string, any>[] }) => any;
  buttonTooltipChildren?: (arg: { selectedIds: string[] }) => React.ReactNode;
  href?: any;
  icon?: IconTypes;
  iconPosition?: 'left' | 'right';
  isDisabled?: (arg: {
    isSelectedList: { [id: number]: boolean };
    selectedIds: string[];
    selectedObjects: Record<string, any>[];
  }) => boolean;
  isDisplayButtonTooltip?: boolean;
  onClick?: (arg: { isSelectedList: { [id: number]: boolean }; selectedIds: string[]; selectedObjects: Record<string, any>[] }) => void;
  rotateIcon?: '0' | '90' | '180' | '270';
  title: string;
}

export interface InformationProps {
  actions?: ITableAction[];
  defaultValue?: number;
  extendDirection?: 'before' | 'after' | 'both';
  informationMessage?: string;
  isInformationVisible?: boolean;
  isSelectedList: { [id: number]: boolean };
  isTableSelectionVisible?: boolean;
  itemName: string;
  onPageSizeChange?: (evt: any) => void;
  selectedObjects: Record<string, any>[];
  selectionOptions?: any;
  setSelectedTable?: any;
  totalCount?: number;
}

function Information({
  actions = [],
  defaultValue = 0,
  extendDirection,
  informationMessage,
  itemName,
  totalCount,
  isSelectedList,
  onPageSizeChange,
  isInformationVisible,
  isTableSelectionVisible,
  selectionOptions,
  setSelectedTable,
  selectedObjects,
}: InformationProps) {
  const selectedIds = Object.entries(isSelectedList)
    .filter(([, value]) => value)
    .map(([key]) => key);

  return (
    <div
      className={cx(
        `h-section-item-background-extend--${extendDirection}`,
        'l-full-width',
        'h-background--color-grey-300',
        'h-with-padding',
        'l-flex',
        'l-flex--justify-space-between',
        {
          'h-with-left-padding': extendDirection === 'after',
          'h-with-right-padding': extendDirection === 'before',
        },
      )}
    >
      <span className="l-flex l-flex--align-center l-flex--mobile-column">
        {totalCount > 0 && (
          <span className="l-flex__item--row l-flex l-flex--align-center">
            <span className="l-flex__item--row h-circled h-background--color-brand l-flex l-flex--center c-typography--bold">
              {totalCount}
            </span>
            <span className="l-flex__item--row"> {pluralize(itemName, totalCount)}</span>
          </span>
        )}
        {/* eslint-disable-next-line */}
        {selectedIds.length > 0 ? (
          <span className="l-flex__item--row l-flex l-flex--align-center">
            <span className="l-flex__item--row h-circled h-background--color-brand l-flex l-flex--center c-typography--bold">
              {selectedIds.length}
            </span>
            <span className="l-flex__item--row"> {pluralize(itemName, selectedIds.length)} selected</span>
          </span>
        ) : isInformationVisible ? (
          <InformationMessage message={informationMessage} />
        ) : (
          Boolean(selectedIds.length) && (
            <span className="l-flex__item--row l-flex l-flex--align-center">
              <span className="l-flex__item--row h-circled h-background--color-brand l-flex l-flex--center c-typography--bold">
                {selectedIds.length}
              </span>
              <span className="l-flex__item--row"> {pluralize(itemName, selectedIds.length)} selected</span>
            </span>
          )
        )}
      </span>
      <span className="l-flex l-flex--mobile-column h-with-2x-large-x-margin c-information-container l-flex-gap">
        {isTableSelectionVisible && (
          <Select
            name="module_list"
            onChange={(value) => {
              setSelectedTable(selectionOptions[value].title);
            }}
            buttonClassName="l-full-width h-no-border c-manage-teachers--table-select"
            isDisabled={selectionOptions.length === 0}
            selectTitle="Modules"
            options={selectionOptions.map(({ id, title }) => {
              return { value: id, content: title };
            })}
            defaultValue={selectionOptions[defaultValue].id}
            color="primary"
          />
        )}

        {actions.map(
          ({
            buttonTooltipChildren,
            title,
            onClick,
            href,
            buildHref,
            icon,
            isDisplayButtonTooltip,
            rotateIcon,
            iconPosition,
            isDisabled,
          }) => {
            const Component = onClick ? Button : ButtonLink;
            if (onClick && href && buildHref) {
              throw new Error('Only one of onClick or href');
            }

            return (
              <div className="admin-alert admin-alert--table-button-tooltip">
                <Component
                  key={title}
                  className="l-flex__item--row"
                  type="button"
                  onClick={onClick && (() => onClick({ selectedIds, isSelectedList, selectedObjects }))}
                  href={href || buildHref?.({ selectedIds, isSelectedList, selectedObjects })}
                  isDisabled={isDisabled?.({
                    selectedIds,
                    isSelectedList,
                    selectedObjects,
                  })}
                  disabledMessage={`To use, please select ${pluralize(itemName).toLowerCase()} from the table.`}
                  icon={icon}
                  rotateIcon={rotateIcon}
                  iconPosition={iconPosition}
                >
                  {title}
                </Component>
                {isDisplayButtonTooltip && buttonTooltipChildren ? (
                  <span className="admin-alert-message-profile">
                    {buttonTooltipChildren?.({ selectedIds })}
                    <span className="admin-alert-message-arrow" />
                  </span>
                ) : null}
              </div>
            );
          },
        )}
        {Boolean(onPageSizeChange) && (
          <Select
            name="module_list"
            onChange={(e) => onPageSizeChange(e)}
            buttonClassName="l-full-width h-no-border c-pagination--table-select"
            defaultValue={10}
            options={[
              { value: 10, content: '10 Students' },
              { value: 20, content: '20 Students' },
              { value: 50, content: '50 Students' },
              { value: 100, content: '100 Students' },
              { value: 9999, content: 'All Students' },
            ]}
          />
        )}
      </span>
    </div>
  );
}

export { Information };
