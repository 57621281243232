import React, { ReactNode } from 'react';

import cx from 'classnames';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import { Button, ButtonLink } from 'components/Shared/Inputs/Button';

import { HeaderCellProps } from '../Header/Header';

interface Cell extends HeaderCellProps {
  content: ReactNode | ((arg: { index: number; isSelected: boolean }) => ReactNode);
}

export interface RowProps {
  className: string;
  columns: Cell[];
  disableSelect?: boolean;
  expandable?: { isExpanded: boolean; onExpandClicked: () => void };
  expandableClassName?: string;
  extendDirection?: 'before' | 'after' | 'both';
  isDisplayUploadedTasks?: boolean;
  isSub?: boolean;
  rowIndex: number;
  selectable?: { isSelected: boolean; onSelectClicked: () => void };
  selectedFlowId?: number;
  studentId?: string;
}

const createKey = (key: string) => key.replace(/ /g, '_').toLowerCase();

function Row({
  className,
  selectable,
  columns,
  disableSelect,
  rowIndex,
  expandable,
  extendDirection = 'both',
  isDisplayUploadedTasks,
  isSub,
  studentId,
  selectedFlowId,
  expandableClassName,
}: RowProps) {
  const isEvenRow = rowIndex % 2 === 0;
  const isSelected = selectable?.isSelected || false;
  const { isMobile, isTablet } = useWindowSize();

  function ResponsiveExtras({ priority, condition = true }) {
    if (!(columns.find((column) => column.priority === priority) && condition)) return null;

    return (
      <>
        <tr
          className={cx(
            className,
            expandableClassName,
            'l-flex',
            'h-with-padding',
            `h-section-item-background-extend--${extendDirection}`,
            'c-table-row',
            {
              'h-background--color-brand': isSelected && isEvenRow,
              'h-background--color-brand-secondary': isSelected && !isEvenRow,
              'h-background--color-grey-300': !isSelected && isEvenRow,
              'h-background--color-white': !isSelected && !isEvenRow,
            },
          )}
          aria-hidden
        >
          {columns
            .filter((column) => column.priority === priority)
            .map(({ justify = 'start', title, content, width, contentClassName }) => {
              return (
                <>
                  <td
                    key={createKey(title)}
                    style={{ maxWidth: `${width}rem`, width: '100%' }}
                    className={cx(
                      'c-table-row__column',
                      'l-flex--column',
                      'l-break-line',
                      'l-flex--align-center',
                      'l-flex__item--row',
                      'h-full-height',
                      `l-flex--align-${justify}`,
                    )}
                  >
                    <div className={cx('l-flex', 'l-flex__item--column', 'c-typography--bold', `l-flex--justify-${justify}`)}>{title}</div>
                    <div className={`l-flex__item--column l-full-height l-flex l-flex--justify-${justify} ${contentClassName}`}>
                      {typeof content === 'function' ? content({ isSelected, index: rowIndex }) : content}
                    </div>
                  </td>
                </>
              );
            })}
          {/* Show information only progress page when no module selected  */}
          {selectedFlowId === 0 && <span className="c-table__no-module-selected-message">Select a module to see student progress</span>}
          <div style={{ display: !isDisplayUploadedTasks && 'none' }} className="admin-alert uploaded-tasks-button-link">
            <ButtonLink
              className="c-table__small_button l-flex l-flex__item--row"
              color="secondary"
              href={`/teacher/manage-students/my-task-uploads/${selectedFlowId}/${studentId}`}
              icon="new_window"
              title={selectedFlowId ? 'Open student tasks' : 'Please select a module'}
              isExternal
              round
              isDisabled={!selectedFlowId}
            />
            <span className="admin-alert-message-profile">
              {selectedFlowId ? "View/edit student's tasks" : 'Please select a module'}
              <span className="admin-alert-message-arrow" />
            </span>
          </div>
        </tr>
      </>
    );
  }

  return (
    <>
      <tr
        className={cx(
          className,
          'l-flex',
          'h-with-y-padding',
          'l-flex--justify-space-between',
          `h-section-item-background-extend--${extendDirection}`,
          {
            'h-background--color-brand': isSelected && !isEvenRow,
            'h-background--color-brand-secondary': isSelected && isEvenRow,
            'h-background--color-white': !isSelected && !isEvenRow && !isSub,
            'h-background--color-grey-300': (!isSelected && isEvenRow) || (isSub && !isEvenRow),

            'h-background--color-grey': isSub && isEvenRow,

            'h-with-left-padding': extendDirection === 'after',
            'h-with-right-padding': extendDirection === 'before',
          },
        )}
      >
        {selectable && (
          <td className="c-table-row__select-all-cell l-flex l-flex--align-center l-flex__item--row">
            <label className="h-sr-only" htmlFor="select-all-in-table">
              Select all elements in table
            </label>
            <input
              id="select-all-in-table"
              type="checkbox"
              className="c-input h-no-margin"
              checked={selectable.isSelected}
              onChange={selectable.onSelectClicked}
              disabled={disableSelect}
            />
          </td>
        )}
        {columns.map(({ justify = 'start', title, content, priority = 0, width }, index) => {
          return (
            <td
              key={title}
              style={{ maxWidth: `${width}rem`, width: '100%' }}
              className={cx(
                'c-table-row__column',
                'l-break-word',
                'l-flex',
                'l-flex--align-center',
                'l-flex__item--row',
                `l-flex--justify-${justify}`,
                {
                  'h-color--brand-secondary': index === 0,
                  'c-typography--bold': index === 0,
                  'h-sr-only': priority === 3 || (priority === 2 && (isTablet || isMobile)) || (priority === 1 && isMobile),
                },
              )}
            >
              {typeof content === 'function' ? content({ isSelected, index: rowIndex }) : content}
            </td>
          );
        })}
        {expandable && (
          <td
            className="c-table-row__select-all-cell l-flex l-flex--justify-end l-flex--align-center l-flex__item--row"
            style={{ maxWidth: `20rem`, width: '100%' }}
            aria-hidden
          >
            <Button
              className="l-flex__item--row c-table__small_button"
              id="expand-all-in-table"
              icon="thick_arrow"
              rotateIcon={expandable.isExpanded ? '180' : '0'}
              round
              onClick={expandable.onExpandClicked}
              isActive={expandable.isExpanded}
            />
          </td>
        )}
      </tr>

      {expandable?.isExpanded && (
        <>
          <ResponsiveExtras priority={4} condition={isMobile} />
          <ResponsiveExtras priority={2} condition={isMobile || isTablet} />
          <ResponsiveExtras priority={3} />
        </>
      )}
    </>
  );
}

export { Row };
