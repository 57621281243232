import { IRowSkeletonProps } from './RowSkeleton.interface';

export const RowSkeleton = ({}: IRowSkeletonProps) => {
  return (
    <>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-grey-300">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-white">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-grey-300">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>

      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-white">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-grey-300">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-white">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-grey-300">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-white">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-grey-300">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-white">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-grey-300">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
      <tr className="l-flex h-with-y-padding l-flex--justify-space-between h-section-item-background-extend--both h-background--color-white">
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
        <td className="skeleton-loader"></td>
      </tr>
    </>
  );
};
