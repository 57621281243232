import { ReactNode, useEffect, useState } from 'react';

import { useClickOutside } from 'utils/hooks/useClickOutside';

import { Button } from 'components/Shared/Inputs/Button';

interface SelectPropType {
  buttonClassName?: string;
  color?: 'primary' | 'secondary' | 'tertiary';
  containerClassName?: string;
  defaultValue?: number | string;
  hideSelection?: boolean;
  id?: string;
  displayTitle?: boolean;
  isDisabled?: boolean;
  isNotFullWidth?: boolean;
  name: string;
  onChange: (value: string) => void;
  options: {
    content: ReactNode;
    value: number | string;
  }[];
  selectStatus?: string;
  selectTitle?: string;
  value?: number | string;
}

function Select({
  selectTitle,
  color,
  name,
  options,
  defaultValue,
  value,
  selectStatus,
  isDisabled,
  onChange,
  displayTitle,
  isNotFullWidth,
  containerClassName,
  buttonClassName,
  id,
  hideSelection,
}: SelectPropType) {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(isOpen, () => setIsOpen(false));

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  function handleSelect({ target: { value: newValue } }) {
    setSelectedValue(newValue);
    setIsOpen(false);
    onChange(String(newValue));
  }

  return (
    <div
      ref={ref}
      className={`l-position--relative ${isNotFullWidth ? '' : 'l-full-width'} ${
        selectStatus && `teacher-upload-task-container-${selectedValue}`
      } ${containerClassName}`}
    >
      <Button
        id={id}
        style={{ marginRight: '2rem' }}
        className={`c-button--hang ${buttonClassName}`}
        icon="arrow"
        rotateIcon={isOpen ? '180' : '0'}
        type="button"
        onClick={() => {
          setIsOpen((val) => !val);
        }}
        isDisabled={isDisabled}
        color={color}
      >
        {hideSelection
          ? selectTitle
          : options.find((option) => String(option.value) === String(selectedValue))?.content || (displayTitle ? selectTitle : 'Select')}
      </Button>
      {isOpen && (
        <ul
          style={{
            position: 'absolute',
            bottom: '1.2rem',
            transform: 'translateY(100%)',
            zIndex: 3,
            maxHeight: '20rem',
            overflowY: 'auto',
          }}
          className="h-border h-border--color-brand l-full-width ml-none"
        >
          {options.map((option, index) => {
            return (
              <li
                key={option.value}
                className={`h-background--color-${index % 2 === 1 ? 'grey-300' : 'white'} h-background--hover-color-grey`}
              >
                <label className="c-typography--color-brand h-with-padding">
                  <input
                    className="h-sr-only"
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={option.value === selectedValue}
                    onChange={handleSelect}
                  />
                  {option.content}
                </label>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export { Select as default };
