import React from 'react';

import cx from 'classnames';
import { useWindowSize } from 'utils/hooks/useWindowSize';

import { Button } from 'components/Shared/Inputs/Button';

export interface HeaderCellProps {
  contentClassName?: string;
  isSortable?: boolean;
  justify?: 'start' | 'center' | 'end';
  label?: string;
  priority?: 0 | 1 | 2 | 3;
  title: string;
  width?: number;
}

export interface HeaderProps {
  columns: HeaderCellProps[];
  disableSelect?: boolean;
  expandable?: { allExpanded: boolean; onAllExpandClicked: () => void };
  extendDirection?: 'before' | 'after' | 'both';
  isSub?: boolean;
  onSortClicked: (columnIndex: number) => void;
  selectable?: { allSelected: boolean; onAllSelectClicked: () => void };
  sorted: { columnIndex: number; direction: -1 | 0 | 1 };
  tableName?: string;
  withMultipleSelect?: boolean;
}

function Header({
  selectable,
  columns,
  disableSelect,
  expandable,
  extendDirection = 'both',
  withMultipleSelect = true,
  isSub,
  onSortClicked,
  sorted,
  tableName,
}: HeaderProps) {
  const { isMobile, isTablet } = useWindowSize();

  return (
    <tr
      className={cx(
        'c-table-header',
        'l-flex',
        'h-with-y-padding',
        'l-flex--justify-space-between',
        `h-section-item-background-extend--${extendDirection}`,
        {
          'h-with-left-padding': extendDirection === 'after',
          'h-with-right-padding': extendDirection === 'before',
          'h-background--color-brand': !isSub,
          'h-background--color-brand-secondary': isSub,
        },
      )}
    >
      {selectable && (
        <th className="c-table-header__select-all-cell l-flex l-flex--align-center l-flex__item--row">
          {withMultipleSelect ? (
            <>
              <label className="h-sr-only" htmlFor="select-all-in-table">
                Select all elements in table
              </label>
              <input
                id="select-all-in-table"
                type="checkbox"
                className="c-input h-no-margin"
                checked={selectable.allSelected}
                onChange={selectable.onAllSelectClicked}
                disabled={disableSelect}
              />
            </>
          ) : (
            <span style={{ width: '1.5rem' }} />
          )}
        </th>
      )}
      {columns.map(({ contentClassName, justify = 'start', title, priority = 0, isSortable, width, label }, index) => {
        return (
          <th
            key={title}
            className={cx('c-table-header__column', 'l-flex', 'l-flex--align-center', 'l-flex__item--row', `l-flex--justify-${justify}`, {
              'h-sr-only': priority === 3 || (priority === 2 && (isTablet || isMobile)) || (priority === 1 && isMobile),
              [contentClassName]: true,
            })}
            style={{ maxWidth: `${width}rem`, width: '100%' }}
          >
            <span aria-label={label || title}>{title}</span>
            {isSortable && (
              <button
                className="l-full-height"
                type="button"
                onClick={() => onSortClicked(index)}
                aria-label={`Sort ${title} alphabetically`}
              >
                <span
                  title="Sorts the visible items"
                  className={cx('c-table-header__column-sort-action', 'h-with-x-margin', {
                    'c-table-header__column-sort-action--is-down': sorted.columnIndex === index && sorted.direction === -1,
                    'c-table-header__column-sort-action--is-up': sorted.columnIndex === index && sorted.direction === 1,
                    'h-color--brand': index === 0,
                    'h-color--brand-secondary': index !== 0,
                  })}
                />
              </button>
            )}
          </th>
        );
      })}
      {expandable && (
        <th
          className="c-table-header__select-all-cell l-flex l-flex--justify-end l-flex--align-center l-flex__item--row c-table-header__column h-background--color-brand"
          style={{ maxWidth: `20rem`, width: '100%' }}
          aria-hidden
        >
          <label className="l-flex__item--row" htmlFor="expand-all-in-table">
            Expand all {tableName}
          </label>

          <Button
            className="l-flex__item--row c-table__small_button expand-all_small_button"
            id="expand-all-in-table"
            icon="thick_arrow"
            rotateIcon={expandable.allExpanded ? '180' : '0'}
            round
            onClick={expandable.onAllExpandClicked}
            isActive={expandable.allExpanded}
          />
        </th>
      )}
    </tr>
  );
}

export { Header };
